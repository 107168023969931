<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image dbap-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">DBAP</h2>
              <p>
              
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col cols="12">
                    <div class="details mt_md--30">
                      <div class="section-title" align="center">
                        <span class="subtitle">Our service</span>
                        <h2 class="heading-title">Dlab Bigdata Analytic Platform</h2>
                        <p class="description">DBAP는 데이터를 관리하고 분석하는 데 필요한 모든 도구를 하나의 플랫폼에 통합한 솔루션입니다.</p>
                        <p class="description">이를 통해 사용자는 데이터 카탈로그 생성, 관리 및 분석 작업을 간편하게 수행할 수 있습니다.</p>
                        <br/><br/><br/><br/>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative">
                        <img class="w-100 mb--30" src="../../assets/images/product/dbap01.png" alt="" />
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12Lo">
                    <div class="details mt_md--30">
                      <div class="section-title">
                        <span class="subtitle">데이터 관리의 핵심</span>
                        <h5 class="heading-title">데이터 카탈로그</h5>
                        <p class="description" align="justify">데이터 카탈로그는 DBAP의 핵심 기능 중 하나로, 다양한 형태와 종류의 데이터 소스를 정의하고 관리할 수 있는 시스템입니다. 사용자는 필요에 따라 데이터를 업로드하고, 설정을 조정하여 자신만의 데이터 카탈로그를 구축할 수 있습니다. 또한, 기존에 등록된 카탈로그를 조합하여 새로운 카탈로그를 생성하는 것도 가능합니다. 이는 빅데이터 분석에서 중요한 역할을 합니다.
                        </p>
                        <br/><br/>
                        <span class="subtitle">간편한 데이터 분석</span>
                        <h5 class="heading-title">분석 노트북</h5>
                        <p class="description" align="justify">분석 노트북은 카탈로그에 기반하여 구축되며, 사용자가 손쉽게 데이터를 선택하고 분석할 수 있는 환경을 제공합니다. 복잡한 코딩 없이도 UI를 통해 데이터 분석을 수행하고, 시각화된 결과를 얻을 수 있습니다. 이는 전문가가 아닌 일반 사용자도 데이터 분석에 쉽게 접근할 수 있도록 합니다.
                        </p>
                        <br/><br/>
                        <span class="subtitle">효율적인 조직 관리</span>
                        <h5 class="heading-title">사용자 설정 및 관리</h5>
                        <p class="description" align="justify">DBAP는 조직 관리에 특화된 설계를 가지고 있습니다. 중간관리자는 데이터 카탈로그를 업로드하고 관리할 수 있으며, 일반 사용자는 이러한 카탈로그에 접근하여 분석 노트북을 사용할 수 있습니다. 이 시스템을 통해 조직 내에서 데이터 관리와 분석이 원활하게 이루어질 수 있습니다.
                        </p>
                        <br/><br/>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
              </v-container>
    </div>

    
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      Header,
      Footer,
      PricingPlan,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
